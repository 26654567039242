import handleScroll from "../utils/scroll";

export default function Hero() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900">
      <div
        aria-hidden="true"
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl shrink-0 lg:mx-0 lg:pt-8">
          <img alt="Your Company" src="assets/logo.png" className="h-11" />
          <h1 className="mt-10 text-pretty text-5xl font-semibold tracking-tight text-white sm:text-7xl">
            Master Your X DMs Effortlessly
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-gray-400 sm:text-xl/8">
            Streamline your chats with custom folders for seamless organization
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              target="_blank"
              href="https://chromewebstore.google.com/detail/twinbox-organize-your-x-d/mghpheeehdhmeechpeihmoafnjdaljdl"
              className="flex items-center gap-2 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
            >
              <img
                src="assets/googlewebstore.png"
                alt="Organize Your DMs"
                className="w-6 h-auto rounded-sm"
              />
              Add to Chrome
            </a>
            <a
              href="#features"
              onClick={handleScroll}
              className="text-sm/6 font-semibold text-white"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>

          {/* Social Proof Section */}
          <div className="mt-36 flex items-center gap-x-6">
            <div className="text-white">
              <p className="text-lg font-medium">
                "TwInbox has completely transformed how I manage my DMs."
              </p>
              <div className="mt-2 flex">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5 text-yellow-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.998 2.25c.342 0 .673.185.845.525l2.096 4.245a.75.75 0 00.566.41l4.674.68a.75.75 0 01.415 1.279l-3.384 3.3a.75.75 0 00-.216.664l.799 4.657a.75.75 0 01-1.088.791l-4.185-2.2a.75.75 0 00-.698 0l-4.185 2.2a.75.75 0 01-1.088-.791l.799-4.657a.75.75 0 00-.216-.664l-3.384-3.3a.75.75 0 01.415-1.279l4.674-.68a.75.75 0 00.566-.41l2.096-4.245a.75.75 0 01.845-.525z"
                    />
                  </svg>
                ))}
              </div>
            </div>
            <img
              src="assets/Berke.png"
              alt="Berke"
              className="h-16 w-16 rounded-full shadow-lg"
            />
          </div>
        </div>

        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <img
              alt="App screenshot"
              src="assets/hero.png"
              width={2432}
              height={1442}
              className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
