import { CheckIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "Trial",
    href: "https://chromewebstore.google.com/detail/twinbox-organize-your-x-d/mghpheeehdhmeechpeihmoafnjdaljdl",
    price: "$0",
    description:
      "The perfect plan if you want to try out TwInbox before committing.",
    features: ["1 Folder"],
    featured: false,
  },
  {
    name: "Pro",
    href: "https://chromewebstore.google.com/detail/twinbox-organize-your-x-d/mghpheeehdhmeechpeihmoafnjdaljdl",
    price: "$5 one-time",
    description:
      "A small fee to unlock the full potential of TwInbox and support its development.",
    features: ["Unlimited Folders", "Sync Folders with all devices"],
    featured: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Pricing() {
  return (
    <div className="relative isolate bg-gray-900 dark:bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-indigo-500 to-purple-600 opacity-30"
        />
      </div>
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base font-semibold text-indigo-400">Pricing</h2>
        <p className="mt-2 text-5xl font-semibold tracking-tight text-white sm:text-6xl">
          Unlock Your Chat Power
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-lg text-center text-gray-300 sm:text-xl">
        Try out our product for free, or unlock its full potential for a small
        fee to support its development.
      </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured
                ? "relative bg-gray-800 shadow-2xl"
                : "bg-gray-700/60 sm:mx-8 lg:mx-0",
              tier.featured
                ? ""
                : tierIdx === 0
                ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
                : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl",
              "rounded-3xl p-8 ring-1 ring-gray-700 sm:p-10"
            )}
          >
            <h3
              id={tier.id}
              className={classNames(
                tier.featured ? "text-indigo-300" : "text-indigo-400",
                "text-base font-semibold"
              )}
            >
              {tier.name}
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span className="text-5xl font-bold tracking-tight text-white">
                {tier.price}
              </span>
            </p>
            <p className="mt-6 text-base leading-7 text-gray-300">
              {tier.description}
            </p>
            <ul role="list" className="mt-10 space-y-4 text-gray-300">
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-400"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <a
              href={tier.href}
              aria-describedby={tier.id}
              className={classNames(
                tier.featured
                  ? "bg-indigo-500 text-white shadow-sm hover:bg-indigo-400"
                  : "bg-gray-700 text-indigo-400 ring-1 ring-indigo-400 hover:ring-indigo-300 hover:text-indigo-300",
                "mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 sm:mt-10"
              )}
            >
              Add to Chrome
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
