const testimonials = [
  {
    body: "Amazing tool, perfect integration with X, the user experience is very clean, totally recommend it!",
    author: {
      name: "Amiswa Z.",
      handle: "amiswa2005",
      imageUrl: "assets/Amiswa.jpg",
    },
  },
  {
    body: "Super useful extension, especially for balancing both business and friendly interactions, highly recommend!",
    author: {
      name: "Ashref Gwader",
      handle: "ashrefgwader",
      imageUrl: "assets/Ashref.jpg",
    },
  },

  {
    body: "It's cool and it will help me in by extending my DM organizer from just pinning conversations",
    author: {
      name: "Marios Vasilakis",
      handle: "M__Vasilakis",
      imageUrl: "assets/Marius.jpg",
    },
  },

  {
    body: "An outstanding tool with flawless integration with X. The user experience is seamless and intuitive—highly recommended!",
    author: {
      name: "Teo Aevin",
      handle: "aevin_io",
      imageUrl: "assets/Teo.jpg",
    },
  },
  {
    body: "I was impressed by how quickly this tool connected with X. The setup was straightforward, and it’s made my workflow much more efficient.",
    author: {
      name: "Jegan",
      handle: "jeggu",
      imageUrl: "assets/Jegan.jpg",
    },
  },

  {
    body: "I love how intuitive this tool is. It integrates so smoothly with X, and the user interface makes it a joy to use. Definitely a game-changer!",
    author: {
      name: "Zai",
      handle: "Mozaic365",
      imageUrl: "assets/Zai.jpg",
    },
  },
];

export default function Testimonials() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base/7 font-semibold text-indigo-400">
            Testimonials
          </h2>
          <p className="mt-2 text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Why people love TwInbox
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.handle}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-gray-800 p-8 text-sm/6">
                  <blockquote className="text-gray-300">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      alt=""
                      src={testimonial.author.imageUrl}
                      className="size-10 rounded-full bg-gray-700"
                    />
                    <div>
                      <div className="font-semibold text-white">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-400">{`@${testimonial.author.handle}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
