export default function LogoCloud() {
  return (
    <div className="bg-gray-900 pb-10 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg/8 font-semibold text-white">
          AS SEEN ON
        </h2>
        <div className="mx-auto mt-5 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <img
            alt="HackerNews"
            src="assets/hackernews.png"
            width={158}
            height={75}
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
          />
          <img
            alt="ProductHunt"
            src="assets/producthunt.png"
            width={158}
            height={75}
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
          />
          <img
            alt="Reddit"
            src="assets/reddit.png"
            width={158}
            height={75}
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
          />
          <img
            alt="X"
            src="assets/x.png"
            width={158}
            height={75}
            className="col-span-2 max-h-20 w-full object-contain sm:col-start-2 lg:col-span-1"
          />
        </div>
      </div>
    </div>
  );
}
