export default function Team() {
  return (
    <div className="bg-gray-900 py-24 md:py-32" id="about">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {/* New Heading */}
        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          Born from Frustration, Built for Productivity
        </h2>

        {/* Founder Profile */}
        <div className="mt-12 flex flex-col gap-10 sm:flex-row">
          <img
            alt="Julian Flieller"
            src="/assets/JulianFlieller.png"
            className="w-24 h-28 rounded-full sm:w-28 sm:h-28"
          />
          <div className="max-w-xl text-center sm:text-left">
            <h3 className="text-lg/8 font-semibold tracking-tight text-white">
              Hey, I`m Julian 👋
            </h3>
            <p className="mt-6 text-base/7 text-gray-400">
              As someone who receives tons of messages a day, I found myself
              constantly frustrated with the lack of organization. <br />
              TwInbox was born out of a need to bring structure to my chaotic
              chat history. <br />
              <br />
              What started as a small tool to help me pin and organize my own
              conversations has grown into a product designed to help others
              like me stay on top of their conversations. I hope it helps you as
              much as it’s helped me!
            </p>
            <ul
              role="list"
              className="mt-6 flex justify-center sm:justify-start gap-x-6"
            >
              <li>
                <a
                  href="https://twitter.com/@fliellerjulian" // Replace with your link
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">X</span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://linkedin.com/in/julian-flieller" // Replace with your link
                  className="text-gray-400 hover:text-gray-300"
                >
                  <span className="sr-only">LinkedIn</span>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    className="h-5 w-5"
                  >
                    <path d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zM6.355 16.338H3.655v-8.59h2.7v8.59z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
