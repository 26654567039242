import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "How does TwInbox store my data?",
    answer:
      "All your data is stored securely on your local machine. TwInbox does not use a remote database, ensuring your chats remain private and only accessible to you.",
  },
  {
    question: "Is TwInbox Free to use?",
    answer:
      "You can try TwInbox for free. However, to support ongoing development, TwInbox Pro is a one-time paid extension for $5. This gives you lifetime access to all features, including unlimited folders.",
  },
  {
    question: "How do I create Folders?",
    answer:
      'After installing the extension, you’ll see a "Folder" section inside your "Messages" tab. Simply click the "+" button to create a new folder and name it.',
  },
  {
    question: "Can I sync my Folders between devices?",
    answer:
      "Yes! TwInbox uses Chrome's Sync API, so your folders will automatically sync across all your devices logged into the same Chrome account.",
  },
  {
    question: "Does TwInbox offer updates and support?",
    answer:
      "Absolutely! I’m constantly working to enhance TwInbox with new updates and features. Also, If you experience any issues or have suggestions, feel free to reach out via Twitter.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-white">
                    <span className="text-base/7 font-semibold">
                      {faq.question}
                    </span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon
                        aria-hidden="true"
                        className="size-6 group-data-[open]:hidden"
                      />
                      <MinusSmallIcon
                        aria-hidden="true"
                        className="size-6 group-[&:not([data-open])]:hidden"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base/7 text-gray-300">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
